import damcorp from '../content/port/damcorp.png'
import tiket from '../content/port/tiketcom.png'
import tanamduit from '../content/port/tanamduit.png'
import manecash from '../content/port/manecash.png'
import ojk from '../content/port/ojk.png'
import kioser from '../content/port/kioser.png'
import rvr from '../content/port/rvr.png'
import bhaca from '../content/port/bhaca.png'
import tordillas from '../content/port/tordillas.png'
import linkaja from '../content/port/linkaja.png'
import mcd from '../content/port/mcd.png'
import ducati from '../content/port/ducati.png'
import axis from '../content/port/axis.png'
import kuy from '../content/port/kuy.png'
import dwp from '../content/port/dwp.png'
import vidio from '../content/port/vidio.png'
import bioskoponline from '../content/port/bioskoponline.png'
import visinema from '../content/port/visinema.png'
import compass from '../content/port/compass.png'
import wahana from '../content/port/wahana.png'
import btpn from '../content/port/btpn.png'
import makeoverid from '../content/port/makeoverid.png'
import dompetamancom from '../content/port/dompetamancom.png'
import jenius from '../content/port/jenius.png'
import xl from '../content/port/xl.png'
import ismaya from '../content/port/ismaya.png'
import viu from '../content/port/viu.png'
import disney from '../content/port/disney.png'
import legit from '../content/port/legit.png'
import antikode from '../content/port/antikode2.png'
import dana from '../content/port/dana.png'
import karafuru from '../content/port/karafuru.png'
import bca from '../content/port/bca.png'
import narasi from '../content/port/narasi.png'
import aji from '../content/port/aji.png'
import aman from '../content/port/aman.png'

export default [
  {
    title: 'Damcorp',
    img: damcorp,
  },
  {
    title: 'Tiket.com',
    img: tiket,
  },
  {
    title: 'Tanamduit',
    img: tanamduit,
  },
  {
    title: 'Mandiri eCash',
    img: manecash,
  },
  {
    title: 'Kioser',
    img: kioser,
  },
  {
    title: 'Bung Hatta Awards',
    img: bhaca,
  },
  {
    title: 'Antikode',
    img: antikode,
  },
  {
    title: 'Tordillas',
    img: tordillas,
  },
  {
    title: 'LinkAja',
    img: linkaja,
  },
  {
    title: 'Ducati',
    img: ducati,
  },
  {
    title: 'KUY! Entertainment',
    img: kuy,
  },
  {
    title: 'Djakarta Warehouse Project',
    img: dwp,
  },
  {
    title: 'Legit Group',
    img: legit,
  },
  {
    title: 'Visinema Pictures',
    img: visinema,
  },
  {
    title: 'RVR Group',
    img: rvr,
  },
  {
    title: 'Compass',
    img: compass,
  },
  {
    title: 'Wahana Kreator',
    img: wahana,
  },
  {
    title: 'Bank BTPN',
    img: btpn,
  },
  {
    title: 'Make Over',
    img: makeoverid,
  },
  {
    title: 'Dompet Aman',
    img: dompetamancom,
  },
  {
    title: 'XL Indonesia',
    img: xl,
  },
  {
    title: 'Axis',
    img: axis,
  },
  {
    title: 'Jenius',
    img: jenius,
  },
  {
    title: "McDonald's",
    img: mcd,
  },
  {
    title: 'Viu',
    img: viu,
  },
  {
    title: 'Bioskoponline.com',
    img: bioskoponline,
  },
  {
    title: 'Disney+',
    img: disney,
  },
  {
    title: 'Vidio.com',
    img: vidio,
  },
  {
    title: 'Ismaya',
    img: ismaya,
  },
  {
    title: 'Dana',
    img: dana,
  },
  {
    title: 'Karafuru',
    img: karafuru,
  },
  {
    title: 'Otoritas Jasa Keuangan',
    img: ojk,
  },
  {
    title: 'Aliansi Jurnalis Independen',
    img: aji,
  },
  {
    title: 'Narasi',
    img: narasi,
  },
  {
    title: 'BCA Insurance',
    img: bca,
  },
  {
    title: 'Amantara',
    img: aman,
  },
]