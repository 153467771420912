import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import Projects from '../components/PortListing.js'
import portfolio from '../../data/portfolio'

export default class Index extends Component {
  render() {
    const { data } = this.props
    
    return (
      <Layout>
        <Helmet>
        <title>Clients - Teguh Aprianto</title>  
        <meta name="description" content="Sejak 2010 hingga saat ini, saya sudah menyelesaikan dan menangani beberapa perusahaan dan juga publik figur, baik itu lokal ataupun yang bukan di Indonesia. Informasi tentang perusahaan, organisasi dan individu yang pernah saya tangani bisa ditemukan di halaman ini." />
        <meta name="image" content="https:///teguh.co/static/teguh-24173b1119c53b198e89d46ee252c57a.png" />
        <meta property="og:url" content="https://teguh.co/portfolio/" />
        <meta property="og:type" content="page" />
        <meta property="og:title" content="Portfolio - Teguh Aprianto" />
        <meta property="og:description" content="Sejak 2010 hingga saat ini, saya sudah menyelesaikan dan menangani beberapa perusahaan dan juga publik figur, baik itu lokal ataupun yang bukan di Indonesia. Informasi tentang perusahaan, organisasi dan individu yang pernah saya tangani bisa ditemukan di halaman ini." />
        <meta property="og:image" content="https:///teguh.co/static/teguh-24173b1119c53b198e89d46ee252c57a.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@secgron" />
        <meta name="twitter:title" content="Portfolio - Teguh Aprianto" />
        <meta name="twitter:description" content="Sejak 2010 hingga saat ini, saya sudah menyelesaikan dan menangani beberapa perusahaan dan juga publik figur, baik itu lokal ataupun yang bukan di Indonesia. Informasi tentang perusahaan, organisasi dan individu yang pernah saya tangani bisa ditemukan di halaman ini." />
        <meta name="twitter:image" content="https:///teguh.co/static/teguh-24173b1119c53b198e89d46ee252c57a.png" />
      </Helmet>
        <div className="container">
            <h1>Clients</h1>
            <p>Hingga saat ini saya sudah menyelesaikan ribuan pekerjaan yang berhubungan dengan keamanan dari puluhan perusahaan, baik itu lokal ataupun yang bukan di Indonesia. Informasi tentang perusahaan atau organisasi yang pernah pernah atau masih bekerja sama dengan saya secara langsung atau melalui pihak ketiga hingga hari ini bisa ditemukan di halaman ini. 
              Halaman ini akan terus diperbaharui ketika saya sudah menyelesaikan pekerjaan lainnya.</p>
            <Projects data={portfolio} />
        </div>  
      </Layout>
    )
  }
}
