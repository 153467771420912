import React from 'react'

export default function Projects({ data }) {
  return (
    <div class="portfolio">
      {data.map((node) => {
        return (
        <div class="portitem">
            <img src={node.img} />
            <p>{node.title}</p>
        </div>
        )
      })}
    </div>
  )
}